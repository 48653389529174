.service-container {
  transition: box-shadow 0.3s ease;
  border-radius: 10px;
  overflow: hidden;
  /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); */
}

.service-container:hover {
  /* box-shadow: 0px 2px 5px #333333; */

  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.service-image {
  transition: transform 0.3s ease;
}

.service-container:hover .service-image {
  transform: scale(1.05);
}
