.course-container {
  transition: box-shadow 0.3s ease;
  border-radius: 10px;
  overflow: hidden;
}

/* .course-container:hover {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
} */

.course-image {
  transition: transform 0.3s ease;
}

.course-container:hover .course-image {
  transform: scale(1.05);
}
