.reviews-style {
  background-image: url("https://i.ibb.co/37xVpBQ/reviews-1.jpg");
  background-size: cover;
  max-width: 100%;
  height: auto;
  padding: 90px 0 50px 0;
  background-repeat: no-repeat;
  color: white;
}
@media only screen and (max-width: 600px) {
  .reviews-style {
    padding: 50px 0 5px 0;
    color: white;
  }
}
