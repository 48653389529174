.footer-style {
  max-width: 100%;
  height: auto;
  margin-inline: auto;
}
.footer-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-menu {
  color: white;
  font-size: 18px;
  padding-right: 25px;
}

.social-icon:hover .fa {
  color: blue;
}

@media only screen and (max-width: 768px) {
  .footer-menu {
    display: block;
    padding: 10px;
  }
  .footer-title {
    display: block;
  }
  .footer-style {
    width: auto;
  }
}
