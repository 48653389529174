.checkOut-btn {
  background-color: #eb7700;
  border: 2px solid #eb7700;
  padding: 5px 15px;
  border-radius: 10px;
  color: white;
  width: 100%;
  margin-top: 25px;
}
.checkOut-btn:hover {
  background-color: black;
  border: 2px solid black;
  color: white;
}
.checkOut-input {
  border: 2px solid black;
  padding: 5px 15px;
  border-radius: 10px;
  width: 100%;
  margin-top: 25px;
}
.checkOut-input:hover {
  border: 2px solid #eb7700;
}
.label-text-alt {
  color: red;
}
