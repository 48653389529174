/* Add blur effect to the navbar */
.navbar {
  backdrop-filter: blur(5px);
}

@media only screen and (max-width: 600px) {
  .logo-title {
    font-size: 16px;
  }
  .nav-logo {
    width: 50px;
    height: 50px;
  }
}
