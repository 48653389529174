.banner-style {
  background-image: url("https://i.ibb.co/RpX76Xv/Basic-Programmer-Banner.jpg");
  /* https://i.ibb.co/dGms7ch/basic-Banne.jpg */
  background-size: cover;
  max-width: 100%;
  height: auto;
  padding: 100px 0 100px 0;
  background-repeat: no-repeat;
  color: white;
  margin-bottom: 50px;
  border-radius: 10px;
}
@media only screen and (max-width: 600px) {
  .banner-style {
    padding: 30px 0 30px 0;
    color: white;
  }
}
